@use '@shared/util-styling/mixins';

.dialog {
  $_dialog-bg-color: #fff;
  $_dialog-corner-radius: var(--mdrt-corner-radius-l);
  $_padding-size-primary: 24px;
  $_padding-size-secondary: 16px;
  $_padding-size-tertiary: 8px;
  $_dialog-shadow: 0 2px 8px 0 #00000029, 0 8px 16px 0 #00000014, 0 16px 32px 0 #0000001f;
  $_dialog-header-border: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-01);
  $_dialog-title-font: var(--mdrt-title-1);

  background-color: $_dialog-bg-color;
  box-shadow: $_dialog-shadow;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    align-items: center;
    border-bottom: $_dialog-header-border;
    display: flex;
    gap: var(--spacing-16);
    justify-content: space-between;
    padding: $_padding-size-secondary $_padding-size-primary;
  }

  &__title {
    font: $_dialog-title-font;
    margin: 0;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $_padding-size-secondary $_padding-size-primary;
  }

  &__footer {
    padding: $_padding-size-tertiary $_padding-size-primary $_padding-size-primary;
  }

  &__loading-area {
    border-bottom-left-radius: $_dialog-corner-radius;
    border-bottom-right-radius: $_dialog-corner-radius;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }

  @include mixins.breakpoint('small') {
    border-radius: $_dialog-corner-radius;
    max-height: 80vh;
    width: auto;
  }

  &:focus {
    outline: none;
  }
}
