@use '@shared/util-styling/mixins';

.overlay {
  $_overlay-color: rgb(0 0 0 / 50%);

  align-items: center;
  background-color: $_overlay-color;
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: var(--z-index-modal);
}

.container {
  height: 100%;
  width: 100%;

  @include mixins.breakpoint('small') {
    height: auto;
    width: auto;
  }
}
