@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

@mixin menu-item-separator {
  background-color: var(--mdrt-color-text-on-light-disabled);
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.wrapper {
  position: relative;
}

.trigger {
  @include mixins.button-icon;

  &__more-icon-wrapper {
    align-items: center;
    display: flex;
    height: tokens.$mdrt-icon-button-icon-size;
    justify-content: center;
    width: tokens.$mdrt-icon-button-icon-size;
  }

  &__more-icon {
    height: 4px;
    width: 16px;
  }
}

.popover {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: var(--z-index-dropdown) !important;
}

.menu {
  background-color: var(--mdrt-color-backgrounds-bg-surface);
  border-radius: var(--mdrt-corner-radius-m);
  box-shadow: var(--mdrt-elevation-01);
  display: flex;
  flex-direction: column;
  font: var(--mdrt-body-3-long);
  padding: var(--spacing-8);
}

.menu-item {
  align-items: center;
  border-radius: var(--mdrt-corner-radius-s);
  color: inherit;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-12);
  padding: var(--spacing-12);
  position: relative;
  text-decoration: none;

  &--with-separator-before {
    margin-top: calc(var(--spacing-8) * 2);

    &::before {
      @include menu-item-separator;

      margin-bottom: var(--spacing-8);
      top: calc(var(--spacing-8) * -1);
    }
  }

  &--with-separator-after {
    margin-bottom: calc(var(--spacing-8) * 2);

    &::after {
      @include menu-item-separator;

      bottom: calc(var(--spacing-8) * -1);
      margin-top: var(--spacing-8);
    }
  }

  &--selected {
    background-color: var(--mdrt-color-backgrounds-bg-primary-selected);
    pointer-events: none;
  }

  &:hover {
    background-color: var(--mdrt-color-backgrounds-bg-neutral-fill);
    cursor: pointer;
    text-decoration: none;
  }
}

.menu-item-icon {
  display: block;
  flex-shrink: 0;
  height: var(--mdrt-size-m);
  width: var(--mdrt-size-m);
}
